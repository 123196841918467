var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"safety-management"},[_c('div',{staticClass:"safety-management-left"},[_c('p',[_vm._v("安全管理")]),_c('div',{staticClass:"safety-management-left-item",style:({
          background:
            _vm.selectedSafetyManagement === 'item1' ? '#F6FAFF' : 'white'
        }),on:{"click":function($event){return _vm.clickSafetyManagement('item1')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item1'),expression:"selectedSafetyManagement === 'item1'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 根据用户实际进行流程和产出物裁剪，定制化管理范围 ")])]),_c('div',{staticClass:"safety-management-left-item",style:({
          background:
            _vm.selectedSafetyManagement === 'item2' ? '#F6FAFF' : 'white'
        }),on:{"click":function($event){return _vm.clickSafetyManagement('item2')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item2'),expression:"selectedSafetyManagement === 'item2'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 针对每份产出物提供Guideline/ Template/ Example，功能安全0基础也能快速上手 ")])]),_c('div',{staticClass:"safety-management-left-item",style:({
          background:
            _vm.selectedSafetyManagement === 'item3' ? '#F6FAFF' : 'white'
        }),on:{"click":function($event){return _vm.clickSafetyManagement('item3')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item3'),expression:"selectedSafetyManagement === 'item3'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 可视化文档状态，项目详情尽在掌握 ")])])]),_c('div',{staticClass:"safety-management-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item1'),expression:"selectedSafetyManagement === 'item1'"}]},[_c('img',{staticStyle:{"width":"927px","margin-right":"5px"},attrs:{"src":require("../assets/image/productOverview/safety-management-item1-1.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item2'),expression:"selectedSafetyManagement === 'item2'"}]},[_c('img',{staticStyle:{"width":"543px","height":"262px","margin-right":"5px"},attrs:{"src":require("../assets/image/productOverview/safety-management-item2-1.png"),"alt":""}}),_c('img',{staticStyle:{"width":"384px","height":"266px"},attrs:{"src":require("../assets/image/productOverview/safety-management-item2-2.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyManagement === 'item3'),expression:"selectedSafetyManagement === 'item3'"}]},[_c('img',{staticStyle:{"width":"927px","margin-right":"5px"},attrs:{"src":require("../assets/image/productOverview/safety-management-item3-1.png"),"alt":""}})])])]),_c('div',{staticClass:"safety-tool-fmea"},[_c('div',{staticClass:"safety-tool-fmea-left"},[_c('p',[_vm._v("安全工具FMEA")]),_c('div',{staticClass:"safety-tool-fmea-left-item",style:({
          background:
            _vm.selectedSafetyToolFmea === 'item1' ? '#E5EDF8' : '#F5FAFF'
        }),on:{"click":function($event){return _vm.clickSafetyToolFmea('item1')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item1'),expression:"selectedSafetyToolFmea === 'item1'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 可视化失效关系网，失效传递一目了然 ")])]),_c('div',{staticClass:"safety-tool-fmea-left-item",style:({
          background:
            _vm.selectedSafetyToolFmea === 'item2' ? '#E5EDF8' : '#F5FAFF'
        }),on:{"click":function($event){return _vm.clickSafetyToolFmea('item2')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item2'),expression:"selectedSafetyToolFmea === 'item2'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 丰富的失效库，提升分析效率和规范化水平 ")])]),_c('div',{staticClass:"safety-tool-fmea-left-item",style:({
          background:
            _vm.selectedSafetyToolFmea === 'item3' ? '#E5EDF8' : '#F5FAFF'
        }),on:{"click":function($event){return _vm.clickSafetyToolFmea('item3')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item3'),expression:"selectedSafetyToolFmea === 'item3'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 支持AIAG VDA新版FMEA表格导出 ")])])]),_c('div',{staticClass:"safety-tool-fmea-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item1'),expression:"selectedSafetyToolFmea === 'item1'"}]},[_c('img',{staticStyle:{"height":"465px"},attrs:{"src":require("../assets/image/productOverview/safety-tool-fmea-item1-1.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item2'),expression:"selectedSafetyToolFmea === 'item2'"}]},[_c('img',{staticStyle:{"height":"465px","margin-right":"50px"},attrs:{"src":require("../assets/image/productOverview/safety-tool-fmea-item2-1.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFmea === 'item3'),expression:"selectedSafetyToolFmea === 'item3'"}]},[_c('img',{staticStyle:{"height":"465px"},attrs:{"src":require("../assets/image/productOverview/safety-tool-fmea-item3-1.png"),"alt":""}})])])]),_c('div',{staticClass:"safety-tool-fta"},[_c('div',{staticClass:"safety-tool-fta-left"},[_c('p',[_vm._v("安全工具FTA")]),_c('div',{staticClass:"safety-tool-fta-left-item",style:({
          background: _vm.selectedSafetyToolFta === 'item1' ? '#F6FAFF' : 'white'
        }),on:{"click":function($event){return _vm.clickSafetyToolFta('item1')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFta === 'item1'),expression:"selectedSafetyToolFta === 'item1'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 自动计算硬件指标SPFM, LFM, PMHF ")])]),_c('div',{staticClass:"safety-tool-fta-left-item",style:({
          background: _vm.selectedSafetyToolFta === 'item2' ? '#F6FAFF' : 'white'
        }),on:{"click":function($event){return _vm.clickSafetyToolFta('item2')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFta === 'item2'),expression:"selectedSafetyToolFta === 'item2'"}],staticStyle:{"position":"absolute","left":"10px","width":"10px","height":"10px","border-radius":"50%","background":"#2284EF"}}),_c('span',{staticStyle:{"text-align":"left","font-size":"18px","font-family":"PingFangSC-Medium, PingFang SC","font-weight":"500","color":"#333333"}},[_vm._v(" 可视化高风险项，改进重点有的放矢 ")])])]),_c('div',{staticClass:"safety-tool-fta-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFta === 'item1'),expression:"selectedSafetyToolFta === 'item1'"}]},[_c('img',{staticStyle:{"height":"430px"},attrs:{"src":require("../assets/image/productOverview/safety-tool-fta-item1-1.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSafetyToolFta === 'item2'),expression:"selectedSafetyToolFta === 'item2'"}]},[_c('img',{staticStyle:{"height":"430px"},attrs:{"src":require("../assets/image/productOverview/safety-tool-fta-item2-1.png"),"alt":""}})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('h1',[_vm._v("ISA智能安全分析软件")]),_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-content-item"},[_c('img',{staticStyle:{"width":"80px","height":"80px","margin-bottom":"0"},attrs:{"src":require("../assets/image/productOverview/synergy.png"),"alt":""}}),_c('p',[_vm._v("协同安全管理")]),_c('span',[_vm._v("定制化管理范围")]),_c('span',[_vm._v("完善的安全管理模块")]),_c('span',[_vm._v("协同式开发管理")])]),_c('div',{staticClass:"banner-content-item"},[_c('img',{staticStyle:{"width":"80px","height":"80px","margin-bottom":"0"},attrs:{"src":require("../assets/image/productOverview/efficiency.png"),"alt":""}}),_c('p',[_vm._v("大幅提升效率")]),_c('span',[_vm._v("一站式安全文件导出")]),_c('span',[_vm._v("用户视角，快速上手")]),_c('span',[_vm._v("专属的安全管理指导")])]),_c('div',{staticClass:"banner-content-item",staticStyle:{"margin-right":"0"}},[_c('img',{staticStyle:{"width":"80px","height":"80px","margin-bottom":"0"},attrs:{"src":require("../assets/image/productOverview/professional.png"),"alt":""}}),_c('p',[_vm._v("专业赋能")]),_c('span',[_vm._v("专业可认证开发模块")]),_c('span',[_vm._v("知识产权数失效库")]),_c('span',[_vm._v("工程化讲解及管控")]),_c('span',[_vm._v("获得ASIL-B认证软件")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-highlight"},[_c('h3',[_vm._v("产品亮点")]),_c('div',{staticStyle:{"width":"96px","height":"6px","background":"#4C84FF","margin-top":"6px","margin-bottom":"40px"}})])}]

export { render, staticRenderFns }