<template>
  <div class="container">
    <div class="banner">
      <h1>ISA智能安全分析软件</h1>
      <div class="banner-content">
        <div class="banner-content-item">
          <img
            style="width: 80px; height: 80px; margin-bottom: 0;"
            src="../assets/image/productOverview/synergy.png"
            alt=""
          />
          <p>协同安全管理</p>
          <span>定制化管理范围</span>
          <span>完善的安全管理模块</span>
          <span>协同式开发管理</span>
        </div>
        <div class="banner-content-item">
          <img
            style="width: 80px; height: 80px; margin-bottom: 0;"
            src="../assets/image/productOverview/efficiency.png"
            alt=""
          />
          <p>大幅提升效率</p>
          <span>一站式安全文件导出</span>
          <span>用户视角，快速上手</span>
          <span>专属的安全管理指导</span>
        </div>
        <div class="banner-content-item" style="margin-right: 0;">
          <img
            style="width: 80px; height: 80px; margin-bottom: 0;"
            src="../assets/image/productOverview/professional.png"
            alt=""
          />
          <p>专业赋能</p>
          <span>专业可认证开发模块</span>
          <span>知识产权数失效库</span>
          <span>工程化讲解及管控</span>
          <span>获得ASIL-B认证软件</span>
        </div>
      </div>
    </div>

    <div class="product-highlight">
      <h3>产品亮点</h3>
      <div
        style="width: 96px; height: 6px; background: #4C84FF; margin-top: 6px; margin-bottom: 40px;"
      ></div>
    </div>
    <div class="safety-management">
      <div class="safety-management-left">
        <p>安全管理</p>
        <div
          @click="clickSafetyManagement('item1')"
          class="safety-management-left-item"
          :style="{
            background:
              selectedSafetyManagement === 'item1' ? '#F6FAFF' : 'white'
          }"
        >
          <div
            v-show="selectedSafetyManagement === 'item1'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            根据用户实际进行流程和产出物裁剪，定制化管理范围
          </span>
        </div>
        <div
          @click="clickSafetyManagement('item2')"
          class="safety-management-left-item"
          :style="{
            background:
              selectedSafetyManagement === 'item2' ? '#F6FAFF' : 'white'
          }"
        >
          <div
            v-show="selectedSafetyManagement === 'item2'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            针对每份产出物提供Guideline/ Template/
            Example，功能安全0基础也能快速上手
          </span>
        </div>
        <div
          @click="clickSafetyManagement('item3')"
          class="safety-management-left-item"
          :style="{
            background:
              selectedSafetyManagement === 'item3' ? '#F6FAFF' : 'white'
          }"
        >
          <div
            v-show="selectedSafetyManagement === 'item3'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            可视化文档状态，项目详情尽在掌握
          </span>
        </div>
      </div>
      <div class="safety-management-right">
        <div v-show="selectedSafetyManagement === 'item1'">
          <img
            style="width: 927px; margin-right: 5px;"
            src="../assets/image/productOverview/safety-management-item1-1.png"
            alt=""
          />
        </div>
        <div v-show="selectedSafetyManagement === 'item2'">
          <img
            style="width: 543px; height: 262px; margin-right: 5px;"
            src="../assets/image/productOverview/safety-management-item2-1.png"
            alt=""
          />
          <img
            style="width: 384px; height: 266px;"
            src="../assets/image/productOverview/safety-management-item2-2.png"
            alt=""
          />
        </div>
        <div v-show="selectedSafetyManagement === 'item3'">
          <img
            style="width: 927px; margin-right: 5px;"
            src="../assets/image/productOverview/safety-management-item3-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="safety-tool-fmea">
      <div class="safety-tool-fmea-left">
        <p>安全工具FMEA</p>
        <div
          @click="clickSafetyToolFmea('item1')"
          class="safety-tool-fmea-left-item"
          :style="{
            background:
              selectedSafetyToolFmea === 'item1' ? '#E5EDF8' : '#F5FAFF'
          }"
        >
          <div
            v-show="selectedSafetyToolFmea === 'item1'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            可视化失效关系网，失效传递一目了然
          </span>
        </div>
        <div
          @click="clickSafetyToolFmea('item2')"
          class="safety-tool-fmea-left-item"
          :style="{
            background:
              selectedSafetyToolFmea === 'item2' ? '#E5EDF8' : '#F5FAFF'
          }"
        >
          <div
            v-show="selectedSafetyToolFmea === 'item2'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            丰富的失效库，提升分析效率和规范化水平
          </span>
        </div>
        <div
          @click="clickSafetyToolFmea('item3')"
          class="safety-tool-fmea-left-item"
          :style="{
            background:
              selectedSafetyToolFmea === 'item3' ? '#E5EDF8' : '#F5FAFF'
          }"
        >
          <div
            v-show="selectedSafetyToolFmea === 'item3'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            支持AIAG VDA新版FMEA表格导出
          </span>
        </div>
      </div>
      <div class="safety-tool-fmea-right">
        <div v-show="selectedSafetyToolFmea === 'item1'">
          <img
            style=" height: 465px;"
            src="../assets/image/productOverview/safety-tool-fmea-item1-1.png"
            alt=""
          />
        </div>
        <div v-show="selectedSafetyToolFmea === 'item2'">
          <img
            style=" height: 465px; margin-right: 50px;"
            src="../assets/image/productOverview/safety-tool-fmea-item2-1.png"
            alt=""
          />
        </div>
        <div v-show="selectedSafetyToolFmea === 'item3'">
          <img
            style=" height: 465px;"
            src="../assets/image/productOverview/safety-tool-fmea-item3-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="safety-tool-fta">
      <div class="safety-tool-fta-left">
        <p>安全工具FTA</p>
        <div
          @click="clickSafetyToolFta('item1')"
          class="safety-tool-fta-left-item"
          :style="{
            background: selectedSafetyToolFta === 'item1' ? '#F6FAFF' : 'white'
          }"
        >
          <div
            v-show="selectedSafetyToolFta === 'item1'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            自动计算硬件指标SPFM, LFM, PMHF
          </span>
        </div>
        <div
          @click="clickSafetyToolFta('item2')"
          class="safety-tool-fta-left-item"
          :style="{
            background: selectedSafetyToolFta === 'item2' ? '#F6FAFF' : 'white'
          }"
        >
          <div
            v-show="selectedSafetyToolFta === 'item2'"
            style="position: absolute; left: 10px; width: 10px;height: 10px;border-radius:50%; background: #2284EF;"
          ></div>
          <span
            style="text-align:left;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;"
          >
            可视化高风险项，改进重点有的放矢
          </span>
        </div>
      </div>
      <div class="safety-tool-fta-right">
        <div v-show="selectedSafetyToolFta === 'item1'">
          <img
            style=" height: 430px;"
            src="../assets/image/productOverview/safety-tool-fta-item1-1.png"
            alt=""
          />
        </div>
        <div v-show="selectedSafetyToolFta === 'item2'">
          <img
            style="height: 430px;"
            src="../assets/image/productOverview/safety-tool-fta-item2-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductOverview",
  data() {
    return {
      selectedSafetyManagement: "item1",
      selectedSafetyToolFmea: "item1",
      selectedSafetyToolFta: "item1"
    };
  },
  computed: {},
  methods: {
    clickSafetyManagement(name) {
      this.selectedSafetyManagement = name;
    },
    clickSafetyToolFmea(name) {
      this.selectedSafetyToolFmea = name;
    },
    clickSafetyToolFta(name) {
      this.selectedSafetyToolFta = name;
    }
  }
};
</script>
<style scoped lang="scss">
.container {
}

.banner {
  height: 770px;
  background-image: url("../assets/image/productOverview/banner.png");
  background-size: 100% 770px;
  background-repeat: no-repeat;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1 {
    height: 90px;
    font-size: 64px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 90px;
    margin-bottom: 0;
  }
}

.banner-content {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 91px;
}

.banner-content-item {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-right: 280px;

  & p {
    height: 20px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin-top: 21px;
    margin-bottom: 18px;
  }

  & span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    opacity: 0.7;
  }
}

.product-highlight {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & h3 {
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
    margin: 129px 0 0;
  }
}

.safety-management {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 550px;
}

.safety-management-left {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 48px;

  & p {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    margin-bottom: 17px;
    margin-top: 0;
  }

  & span {
  }
}

.safety-management-left-item {
  width: 390px;
  padding: 0 10px 0 30px;
  height: 88px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & :hover {
    cursor: pointer;
  }
}

.safety-tool-fmea {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 107px;
  height: 550px;
  background: #f5faff;
}

.safety-tool-fmea-left {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 48px;

  & p {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    margin-bottom: 17px;
    margin-top: 0;
  }

  & span {
  }
}

.safety-tool-fmea-left-item {
  width: 390px;
  padding: 0 10px 0 30px;
  height: 88px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & :hover {
    cursor: pointer;
  }
}

.safety-tool-fmea-right {
  width: 927px;
}

.safety-tool-fta {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 550px;
}

.safety-tool-fta-left {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 48px;

  & p {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    margin-bottom: 17px;
    margin-top: 0;
  }

  & span {
  }
}

.safety-tool-fta-left-item {
  width: 390px;
  padding: 0 10px 0 30px;
  height: 88px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & :hover {
    cursor: pointer;
  }
}

.safety-tool-fta-right {
  width: 927px;
}
</style>
