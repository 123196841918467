import { render, staticRenderFns } from "./productOverview.vue?vue&type=template&id=ed8a80ee&scoped=true&"
import script from "./productOverview.vue?vue&type=script&lang=js&"
export * from "./productOverview.vue?vue&type=script&lang=js&"
import style0 from "./productOverview.vue?vue&type=style&index=0&id=ed8a80ee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed8a80ee",
  null
  
)

export default component.exports